/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, FunctionComponent, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";

import { Modal } from "@/components";
import { AutoCompleteItem, Dropdown } from "@/components/Dropdown/Dropdown";
import { CustomColumnTypes } from "@/enum/list.enum";
import { InputField } from "@/components/InputField/InputField";

import textIcon from "@/assets/icons/textIcon.svg";
import numbersIcon from "@/assets/icons/numbersIcon.svg";
import linkIcon from "@/assets/icons/linkIcon.svg";
import aiIcon from "@/assets/icons/aiIcon.svg";
import checkboxIcon from "@/assets/icons/checkboxIcon.svg";
import diceIcon from "@/assets/icons/diceIcon.svg";
import googleNewsIcon from "@/assets/icons/googleNewsIcon.svg";
import departmentIcon from "@/assets/icons/sidebar/site-map.svg";
import toolIcon from "@/assets/icons/sidebar/screw-driver-wrench.svg";
import linkedinIcon from "@/assets/icons/linkedin.svg";
import jobIcon from "@/assets/icons/job.svg";

import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";

import { ButtonColor } from "@/components/Button/types";

import { useAddColumnToTrackerMutation } from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { CustomColumn, CustomColumnSchema } from "../../../../List/schema/list";

import { GoogleNewsModal } from "./GoogleNewsModal";
import { AiModal } from "./AIModal";
import { RandomValueModal } from "./RandomValueModal";
import { LinkedinPostModal } from "./LinkedinPostModal";
import { JobOfferModal } from "./JobOfferModal";
import { TechnologyModal } from "./TechnologyModal";
import { DepartmentModal } from "./DepartmentModal";

export interface CreateCustomColumnModalProps {
	setOpen: (value: boolean) => void;
	open: boolean;
	trackerId: number;
	customColumnTypes?: CustomColumnTypes[];
}

export const CreateCustomColumnModal: FunctionComponent<CreateCustomColumnModalProps> = ({
	open,
	setOpen,
	trackerId,
	customColumnTypes,
}) => {
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`aiColumn.${key}`), [t]);

	const [columnType, setColumnType] = useState<CustomColumnTypes>();

	const [update, { isLoading, error }] = useAddColumnToTrackerMutation();

	const [showConfirm, setShowConfirm] = useState<boolean>(false);

	const {
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CustomColumn>({
		resolver: zodResolver(CustomColumnSchema),
	});

	const onSubmit = async (values: CustomColumn) => {
		try {
			await update({
				id: trackerId,
				...values,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<Modal
			handleClose={() => {
				setShowConfirm(true);
			}}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={open}
			overflow={true}
			size="xs"
			submitButtonText={t("basics.confirm")}
			subtitle="Select the column type to proceed to the next step."
			title="Add Column"
		>
			<div className="flex flex-col space-y-4">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setValue("customColumnName", event.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					label="Column Name"
					name={"name"}
					placeholder="Enter column name"
					value={getValues("customColumnName")}
				/>

				<Dropdown
					data={[
						{
							title: ts(CustomColumnTypes.TEXT),
							id: CustomColumnTypes.TEXT,
							icon: <img alt="textIcon" className="mr-1 w-[20px] h-[20px]" src={textIcon} />,
						},
						{
							title: ts(CustomColumnTypes.NUMBER),
							id: CustomColumnTypes.NUMBER,
							icon: <img alt="numbersIcon" className="mr-1" src={numbersIcon} />,
						},
						{
							title: ts(CustomColumnTypes.LINK),
							id: CustomColumnTypes.LINK,
							icon: <img alt="linkIcon" className="mr-1 w-[20px] h-[20px]" src={linkIcon} />,
						},
						{
							title: ts(CustomColumnTypes.BOOLEAN),
							id: CustomColumnTypes.BOOLEAN,
							icon: <img alt="linkIcon" className="mr-1 w-[20px] h-[20px]" src={checkboxIcon} />,
						},
						{
							title: ts(CustomColumnTypes.AI_PROMPT),
							id: CustomColumnTypes.AI_PROMPT,
							icon: <img alt="linkIcon" className="mr-1 w-[20px] h-[20px]" src={aiIcon} />,
						},
						{
							title: ts(CustomColumnTypes.RANDOM_VALUE),
							id: CustomColumnTypes.RANDOM_VALUE,
							icon: <img alt="linkIcon" className="mr-1 w-[20px] h-[20px]" src={diceIcon} />,
						},
						{
							title: ts(CustomColumnTypes.GOOGLE_NEWS),
							id: CustomColumnTypes.GOOGLE_NEWS,
							icon: <img alt="linkIcon" className="mr-1 w-[20px] h-[20px]" src={googleNewsIcon} />,
						},
						{
							title: ts(CustomColumnTypes.DEPARTMENT_SIZE),
							id: CustomColumnTypes.DEPARTMENT_SIZE,
							icon: <img alt="linkIcon" className="mr-1 w-[20px] h-[20px]" src={departmentIcon} />,
						},
						{
							title: ts(CustomColumnTypes.LINKEDIN_POST),
							id: CustomColumnTypes.LINKEDIN_POST,
							icon: <img alt="linkIcon" className="mr-1 w-[20px] h-[20px]" src={linkedinIcon} />,
						},
						{
							title: ts(CustomColumnTypes.JOB_OFFER),
							id: CustomColumnTypes.JOB_OFFER,
							icon: <img alt="linkIcon" className="mr-1 w-[20px] h-[20px]" src={jobIcon} />,
						},
						{
							title: ts(CustomColumnTypes.TECHNOLOGY),
							id: CustomColumnTypes.TECHNOLOGY,
							icon: <img alt="linkIcon" className="mr-1 w-[20px] h-[20px]" src={toolIcon} />,
						},
					]?.filter((c) => customColumnTypes?.includes(c.id) || !customColumnTypes)}
					floating={true}
					handleSelect={function (value?: AutoCompleteItem) {
						if (value?.id) {
							setValue("customColumnType", value.id as CustomColumnTypes);
							setColumnType(value.id as CustomColumnTypes);
						}
					}}
					label="Custom Column Type"
				/>

				{columnType === CustomColumnTypes.RANDOM_VALUE && (
					<RandomValueModal
						open={true}
						setOpen={setOpen}
						setShowConfirm={setShowConfirm}
						trackerId={trackerId}
					/>
				)}

				{columnType === CustomColumnTypes.TECHNOLOGY && (
					<TechnologyModal
						open={true}
						setOpen={setOpen}
						setShowConfirm={setShowConfirm}
						trackerId={trackerId}
					/>
				)}

				{columnType === CustomColumnTypes.DEPARTMENT_SIZE && (
					<DepartmentModal
						open={true}
						setOpen={setOpen}
						setShowConfirm={setShowConfirm}
						trackerId={trackerId}
					/>
				)}

				{columnType === CustomColumnTypes.GOOGLE_NEWS && (
					<GoogleNewsModal
						open={true}
						setOpen={setOpen}
						setShowConfirm={setShowConfirm}
						trackerId={trackerId}
					/>
				)}

				{columnType === CustomColumnTypes.AI_PROMPT && (
					<AiModal
						open={true}
						setOpen={setOpen}
						setShowConfirm={setShowConfirm}
						trackerId={trackerId}
					/>
				)}

				{columnType === CustomColumnTypes.LINKEDIN_POST && (
					<LinkedinPostModal
						open={true}
						setOpen={setOpen}
						setShowConfirm={setShowConfirm}
						trackerId={trackerId}
					/>
				)}

				{columnType === CustomColumnTypes.JOB_OFFER && (
					<JobOfferModal
						open={true}
						setOpen={setOpen}
						setShowConfirm={setShowConfirm}
						trackerId={trackerId}
					/>
				)}

				{getAllErrors(error, formErrors).length
					? renderErrorMessages(getAllErrors(error, formErrors))
					: null}
			</div>
			{showConfirm && (
				<Modal
					handleClose={() => setShowConfirm(false)}
					handleSave={async () => {
						setShowConfirm(false);
						setOpen(false);
					}}
					isOpened={showConfirm}
					size="xs"
					submitButtonColor={ButtonColor.ACTION}
					submitButtonText={t("basics.confirm")}
					title={t("basics.confirm")}
				>
					<>{t("basics.confirmationClose")} </>
				</Modal>
			)}
		</Modal>
	);
};
