import { z } from "zod";

import { MetaDataSchema } from "@/schemas/meta-data";

import { ERROR_TYPE } from "@/utils";

import { OrganizationSchema } from "../../Apollo/schema/get-people";
import { ColumnValueSchema } from "../../List/schema/columnValue";
import { TrackerSchema } from "./profileTracker";
import { TrackerPersonSchema } from "./trackerPerson";

const { REQUIRED } = ERROR_TYPE;

export const TrackerOrganizationSchema = z.object({
	id: z.number(),
	name: z.string().min(4, { message: REQUIRED }).trim(),
	tracker: TrackerSchema,
	organization: OrganizationSchema,
	columns: z.array(ColumnValueSchema),
	columnsHistory: z.array(ColumnValueSchema),
	trackerPersons: z.array(TrackerPersonSchema).optional(),
});

export const TrackerOrganizationResponseSchema = z.object({
	data: z.array(TrackerOrganizationSchema),
	meta: MetaDataSchema,
});

export type TrackerOrganization = z.infer<typeof TrackerOrganizationSchema>;

export type TrackerOrganizationResponse = z.infer<typeof TrackerOrganizationResponseSchema>;
