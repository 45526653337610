import { ChangeEvent, FunctionComponent } from "react";

import { CircularProgress } from "@mui/material";

import { InputField } from "@/components/InputField/InputField";
import { CustomColumnTypes } from "@/enum/list.enum";
import { classNames } from "@/utils/classNames";

import { useUpdateColumnValueMutation } from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { TrackerOrganization } from "../../schema/trackerOrganizations";
import { ColumnConfig } from "../../../List/schema/columnConfig";
import { ColumnValue } from "../../../List/schema/columnValue";

export interface CustomNumberColumnProps {
	showEdit?: { id: number; name: string };
	columnValue?: ColumnValue;
	columnConfig: ColumnConfig;
	row: TrackerOrganization;
	customColumnNumberValue?: number | null;
	setCustomColumnNumberValue: (value: number | null) => void;
	setShowEdit: (value: { id: number; name: string }) => void;
	type: CustomColumnTypes;
}

export const CustomNumberColumn: FunctionComponent<CustomNumberColumnProps> = ({
	showEdit,
	columnValue,
	columnConfig,
	row,
	customColumnNumberValue,
	setCustomColumnNumberValue,
	setShowEdit,
	type,
}) => {
	const [updatePerson, { isLoading }] = useUpdateColumnValueMutation();

	if (isLoading) {
		return <CircularProgress />;
	}

	if (showEdit?.id === row.id && showEdit?.name === columnConfig.name) {
		return (
			<InputField
				autoFocus
				handleBlur={() => {
					setShowEdit({ id: 0, name: "" });
				}}
				handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
					setCustomColumnNumberValue(+event.target.value || null);
				}}
				name={columnConfig.name}
				type="number"
				value={customColumnNumberValue || ""}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						updatePerson({
							id: row.id,
							columnId: columnConfig.id,
							customColumnName: columnConfig.name,
							value: customColumnNumberValue || null,
							customColumnType: type,
						});
						setCustomColumnNumberValue(null);
						setShowEdit({ id: 0, name: "" });
					}
				}}
			/>
		);
	} else {
		return (
			<button
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();

					setCustomColumnNumberValue(columnValue?.value ? (columnValue.value as number) : null);

					setShowEdit({ id: row.id, name: columnConfig.name });
				}}
			>
				{
					<div
						className={classNames(
							"h-[26px] min-w-[100px] cursor-pointer hover:bg-brand-light hover:opacity-[0.7] leading-tight hover:text-white rounded p-1",
							"text-left"
						)}
					>
						{columnValue?.value || ""}
					</div>
				}
			</button>
		);
	}
};
