/* eslint-disable @typescript-eslint/no-explicit-any */
import { faCoin } from "@fortawesome/pro-regular-svg-icons";

import { ChangeEvent, FunctionComponent, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";

import { Icon, Modal } from "@/components";
import { useHasPermissions } from "@/utils/useHasPermissions";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import { AiApplyType, CustomColumnFrequency, CustomColumnTypes } from "@/enum/list.enum";
import { PermissionRoles } from "@/enum";
import { InputField } from "@/components/InputField/InputField";
import { Dropdown, AutoCompleteItem } from "@/components/Dropdown/Dropdown";
import { profileTrackerSelector } from "@/pages/Private/redux/profileTracker/profileTracker.slice";
import { useAppSelector } from "@/redux/hooks";
import { useAddColumnToTrackerMutation } from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { Radio } from "@/components/Radio/Radio";
import { CustomColumn, CustomColumnSchema } from "@/pages/Private/pages/List/schema/list";
import { ColumnConfig } from "@/pages/Private/pages/List/schema/columnConfig";

export interface JobOfferModalProps {
	setShowConfirm: (value: boolean) => void;
	open: boolean;
	trackerId: number;
	setOpen: (value: boolean) => void;
	columnId?: number;
	column?: ColumnConfig;
}

export const JobOfferModal: FunctionComponent<JobOfferModalProps> = ({
	setShowConfirm,
	trackerId,
	open,
	setOpen,
	columnId,
	column,
}) => {
	const { hasPermissions } = useHasPermissions();

	const [frequency, setFrequency] = useState<CustomColumnFrequency>();

	const [updateOrOnce, setUpdateOrOnce] = useState<boolean>(false);
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`aiColumn.${key}`), [t]);
	const [sumUpScoreOverTime, setSumUpScoreOverTime] = useState<boolean>(false);

	const [update, { isLoading, error }] = useAddColumnToTrackerMutation();
	const { records } = useAppSelector(profileTrackerSelector);

	console.log(column);

	const {
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CustomColumn>({
		defaultValues: {
			customColumnType: CustomColumnTypes.JOB_OFFER,
			...column,
			customColumnName: column?.name,
			value: column?.config,
		},
		resolver: zodResolver(CustomColumnSchema),
	});

	console.log(errors);

	const onSubmitSaveOnly = async (values: CustomColumn) => {
		try {
			await update({
				...values,
				aiApplyType: AiApplyType.NONE,
				id: trackerId,
				trackerOrganizationIds: records.slice(0, 10).map((p) => p.id),
				updateOrOnce,
				frequency,
				sumUpScoreOverTime,
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const onSubmit = async (values: CustomColumn) => {
		try {
			await update({
				...values,
				id: trackerId,
				updateOrOnce,
				frequency,
				sumUpScoreOverTime,
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const onSubmitFirst10 = async (values: CustomColumn) => {
		try {
			await update({
				...values,
				aiApplyType: AiApplyType.FIRST_10,
				id: trackerId,
				trackerOrganizationIds: records.slice(0, 10).map((p) => p.id),
				updateOrOnce,
				frequency,
				sumUpScoreOverTime,
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<Modal
			handleClose={() => {
				setShowConfirm(true);
			}}
			handleSave={handleSubmit(onSubmit)}
			handleSecondSave={handleSubmit(onSubmitSaveOnly)}
			handleThirdSave={handleSubmit(onSubmitFirst10)}
			isLoading={isLoading}
			isOpened={open}
			secondSubmitButtonText={t("basics.save")}
			size="md"
			submitButtonText={ts("applyToAll")}
			thirdSubmitButtonText={ts("applyTo10")}
			title="Create Job offer Column"
		>
			<div className="text-ssm font-normal text-xs text-gray-700 mb-2">
				We search for jobs that have been posted in the last 30 days.
			</div>
			<div className="text-ssm font-medium mb-2">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setValue("customColumnName", event.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					label="Column Name"
					name={"name"}
					placeholder="Enter column name"
					value={getValues("customColumnName")}
				/>
			</div>

			<div className="text-ssm font-medium mb-2">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setValue("value", event.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					label="Job Title"
					name={"value"}
					placeholder="Enter job title"
					value={getValues("value")}
				/>
			</div>
			<div className="text-ssm font-normal text-xs text-gray-700 mb-2">
				Leave empty if you want to get the last job offer
			</div>

			<div className="flex flex-col mt-5 border-t pt-5 border-gray-200">
				<div className="flex  gap-4 flex-col text-gray-700 items-center">
					<Dropdown
						data={[
							{
								title: ts(CustomColumnFrequency.ONCE),
								id: CustomColumnFrequency.ONCE,
							},
							{
								title: ts(CustomColumnFrequency.WEEKLY),
								id: CustomColumnFrequency.WEEKLY,
							},
							{
								title: ts(CustomColumnFrequency.MONTHLY),
								id: CustomColumnFrequency.MONTHLY,
							},
							{
								title: ts(CustomColumnFrequency.QUATERLY),
								id: CustomColumnFrequency.QUATERLY,
							},
							{
								title: ts(CustomColumnFrequency.HALFYEARLY),
								id: CustomColumnFrequency.HALFYEARLY,
							},
							{
								title: ts(CustomColumnFrequency.YEARLY),
								id: CustomColumnFrequency.YEARLY,
							},
						]}
						defaultValue={{
							title: ts(CustomColumnFrequency.ONCE),
							id: CustomColumnFrequency.ONCE,
						}}
						floating={true}
						handleSelect={function (value?: AutoCompleteItem) {
							if (value?.id) {
								setValue("frequency", value.id as CustomColumnFrequency);
								setFrequency(value.id as CustomColumnFrequency);
							}
						}}
						label="Execution frequency"
					/>
				</div>
				<div className="flex flex-col grow mr-2">
					<div className="text-ssm font-medium mt-4 mb-2">Update frequency</div>
					<div className="flex flex-col gap-4">
						<Radio
							className="whitespace-nowrap"
							isChecked={updateOrOnce}
							onChange={() => {
								setUpdateOrOnce(!updateOrOnce);
							}}
						>
							{ts("updateOnEachExecution")}
						</Radio>

						<Radio
							className="whitespace-nowrap"
							isChecked={!updateOrOnce}
							onChange={() => {
								setUpdateOrOnce(!updateOrOnce);
							}}
						>
							{ts("onceAfterFindingAValue")}
						</Radio>
					</div>
				</div>
			</div>
			<div className="flex flex-col mt-5 border-t pt-5 border-gray-200">
				<div className="flex flex-col grow mr-2">
					<div className="text-ssm font-medium mb-2">Scoring</div>
					<div className="flex flex-col gap-4">
						<InputField
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								setValue("score", +event.target.value, {
									shouldValidate: true,
									shouldDirty: true,
								});
							}}
							name={"score"}
							placeholder="Enter score"
							type="number"
							value={getValues("score")?.toString() || ""}
						/>
					</div>
				</div>
				<div className="flex flex-col grow mr-2">
					<div className="text-ssm font-medium mt-4 mb-2">Sum up score over time</div>
					<div className="flex flex-col gap-4">
						<Radio
							className="whitespace-nowrap"
							isChecked={sumUpScoreOverTime}
							onChange={() => {
								setSumUpScoreOverTime(!sumUpScoreOverTime);
							}}
						>
							{ts("sumUpScoreOverTime")}
						</Radio>

						<Radio
							className="whitespace-nowrap"
							isChecked={!sumUpScoreOverTime}
							onChange={() => {
								setSumUpScoreOverTime(!sumUpScoreOverTime);
							}}
						>
							{ts("doNotSumUpScoreOverTime")}
						</Radio>
					</div>
				</div>
			</div>
			{hasPermissions([PermissionRoles.GLOBAL_ADMIN]) && (
				<div className="flex flex-row mt-5 border-t pt-5 border-gray-200 text-gray-700 items-center text-sm">
					<span className="mr-1">2x</span>
					<Icon className="w-[16px] h-[16px] text-gray-700 mr-1" icon={faCoin} />
					<span className="">per row</span>
				</div>
			)}
			{getAllErrors(error, formErrors).length ? (
				<div className="mt-4 mb-0">{renderErrorMessages(getAllErrors(error, formErrors))}</div>
			) : null}
		</Modal>
	);
};
