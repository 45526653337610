import { ChangeEvent, FunctionComponent, useCallback, useEffect, useRef, useState } from "react";

import ReactDOM from "react-dom";

import { format } from "date-fns";

import { Link } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, TextField } from "@mui/material";

import { useTranslation } from "react-i18next";

import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons";

import { ReactComponent as ChevronDoubleRight } from "@assets/icons/chevronDoubleRight.svg";
import { ReactComponent as PhoneIcon } from "@assets/icons/phone.svg";
import { ReactComponent as ExternalLinkIcon } from "@assets/icons/launch.svg";

import { SvgIcon } from "@/components/Icon/SvgIcon";

import { CustomColumnTypes } from "@/enum/list.enum";

import { classNames } from "@/utils/classNames";

import { Button } from "@/components/Button/Button";

import { ButtonSize } from "@/components/Button/types";

import { Icon, Modal } from "@/components";
import { SearchField } from "@/components/SearchField/SearchField";
import { getAllErrors, renderErrorMessages, useSearch } from "@/utils";

import { useLazyGetSearchPersonSuggestionQuery } from "@/pages/Private/redux/liPeople/liPeople.api";

import { Checkbox } from "@/components/Checkbox/Checkbox";

import {
	useAddPeopleToTrackerOrganizationMutation,
	useRemovePersonFromTrackerOrganizationMutation,
} from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { LinkedinIcon } from "@/components/LinkedinIcon/LinkedinIcon";

import { getSvgIcons } from "../../utils/getIconsByType";
import { TrackerOrganization } from "../../schema/trackerOrganizations";
import { PersonSearch, PersonSearches } from "../../../LiPeople/schema/liPeople";
import { TrackerPerson } from "../../schema/trackerPerson";

interface DetailViewProps {
	trackerOrganization?: TrackerOrganization;
	setShowDetailView: (value: boolean) => void;
}

enum Tabs {
	ACTIVITY = "ACTIVITY",
	PROFILES = "PROFILES",
}

export const DetailView: FunctionComponent<DetailViewProps> = ({
	trackerOrganization,
	setShowDetailView,
}) => {
	const { t } = useTranslation();
	const ts = (key: string) => t(`profileTracker.${key}`);

	const ref = useRef<HTMLDivElement>(null);

	const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.ACTIVITY);
	const [showSearchPerson, setShowSearchPerson] = useState<boolean>(false);
	const [showRemovePerson, setShowRemovePerson] = useState<boolean>(false);

	const [fetchPeople] = useLazyGetSearchPersonSuggestionQuery();
	const [
		removePersonFromTrackerOrganization,
		{ isLoading: removePersonIsLoading, error: errorRemoving },
	] = useRemovePersonFromTrackerOrganizationMutation();

	const { searchValue, query, setSearchValue } = useSearch();

	const [selectedPeople, setSelectedPeople] = useState<PersonSearch[]>([]);

	const [selectedTrackerPerson, setSelectedTrackerPerson] = useState<TrackerPerson>();

	const [people, setPeople] = useState<PersonSearches>();

	const [addPeopleToTrackerOrganization, { isLoading, error }] =
		useAddPeopleToTrackerOrganizationMutation();

	useEffect(() => {
		const fetchPeopleFromAPI = async (keyword: string) => {
			const people = await fetchPeople({
				keyword,
				organizationId: trackerOrganization?.organization.id,
			}).unwrap();

			setPeople(people);
		};

		if (query) {
			fetchPeopleFromAPI(query);
		}
	}, [fetchPeople, query, trackerOrganization?.organization.id]);

	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside({ target }: MouseEvent) {
			if (
				target &&
				ref.current &&
				!ref.current.contains(target as Node) &&
				!showSearchPerson &&
				!showRemovePerson
			) {
				setShowDetailView(false);
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, setShowDetailView, showSearchPerson, showRemovePerson]);

	const handleTabsChange = useCallback((_: ChangeEvent<{}>, value: Tabs): void => {
		setCurrentTab(value);
	}, []);

	const tabs = [
		{ label: ts("tabs.ACTIVITY"), value: Tabs.ACTIVITY },
		{ label: ts("tabs.PROFILES"), value: Tabs.PROFILES },
	];

	const activityDetails = (
		<div className="mb-8 flex flex-col">
			{trackerOrganization?.columnsHistory
				?.filter((c) => {
					return (
						c?.columnConfig?.type &&
						[
							CustomColumnTypes.AI_PROMPT,
							CustomColumnTypes.DEPARTMENT_SIZE,
							CustomColumnTypes.GOOGLE_NEWS,
							CustomColumnTypes.JOB_OFFER,
							CustomColumnTypes.TECHNOLOGY,
							CustomColumnTypes.LINKEDIN_POST,
						].includes(c.columnConfig.type)
					);
				})
				?.map((column, index) => (
					<div key={index} className="flex flex-col border border-gray-200 rounded-xl mb-4">
						<div className="text-smd font-semibold flex flex-row items-center p-4 ">
							{column?.columnConfig?.name}
							{column?.columnConfig?.type && (
								<img className="h-[24px] w-auto ml-2" src={getSvgIcons(column.columnConfig.type)} />
							)}
						</div>

						{column.columnConfig.type === CustomColumnTypes.GOOGLE_NEWS && (
							<>
								{column.value?.answer && (
									<div className="text-ssm font-semibold px-4 mb-2">
										Answer: {column.value?.answer}
									</div>
								)}
								<div className="text-ssm font-normal px-4 mb-2">{column.value?.title}</div>
								<div className="text-xs font-thin px-4 mb-1">{column.value?.snippet || ""}</div>
								<div className="text-xs font-thin px-4 mb-2">
									<Link target="_blank" to={column.value.newsUrl}>
										Read more...
									</Link>{" "}
								</div>
							</>
						)}
						{column.columnConfig.type === CustomColumnTypes.LINKEDIN_POST && (
							<>
								{column.value?.answer && (
									<div className="text-ssm font-semibold px-4 mb-2">
										Answer: {column.value?.answer}
									</div>
								)}
								<div className="text-ssm font-normal px-4 mb-2">
									{column.value?.actor?.actor_name}
								</div>
								<div className="text-xs font-thin px-4 mb-1">{column.value?.commentary || ""}</div>
								<div className="text-xs font-thin px-4 mb-2">
									<Link target="_blank" to={column.value.share_url}>
										Read more...
									</Link>{" "}
								</div>
							</>
						)}
						{![CustomColumnTypes.GOOGLE_NEWS, CustomColumnTypes.LINKEDIN_POST].includes(
							column.columnConfig.type
						) && (
							<div className="text-ssm font-normal px-4 mb-2">
								Answer: {column.value?.answer || column.value}
							</div>
						)}

						<div className="text-xs font-thin text-gray-500 border-t px-4 py-2 flex justify-end  border-divider">
							{format(new Date(column.createdAt), "d MMM yyyy")}
						</div>
					</div>
				))}
		</div>
	);

	const savePeople = async () => {
		if (trackerOrganization) {
			await addPeopleToTrackerOrganization({
				id: trackerOrganization.id,
				people: selectedPeople,
			}).unwrap();
		}

		setSelectedPeople([]);
		setShowSearchPerson(false);
	};

	const removePerson = async (person: TrackerPerson) => {
		setSelectedTrackerPerson(person);
		setShowRemovePerson(true);
	};

	const handleRemovePersonFromTrackerOrganization = async () => {
		await removePersonFromTrackerOrganization({
			personId: selectedTrackerPerson?.id || 0,
			id: trackerOrganization?.id || 0,
		}).unwrap();

		setSelectedTrackerPerson(undefined);
		setShowRemovePerson(false);
	};

	return ReactDOM.createPortal(
		<div
			ref={ref}
			className="absolute overflow-y-auto right-0 top-0 w-1/3 w-[800px] max-w-[80vw] h-full bg-white rounded-tl-[32px] shadow-lg z-[150] transition-opacity text-sm"
		>
			<div
				className="flex justify-between items-center h-[48px] px-8 border-b border-divider mb-8"
				onClick={() => setShowDetailView(false)}
			>
				<SvgIcon className="w-[24px] h-[24px]" svgIcon={ChevronDoubleRight} />
			</div>
			<div className="mb-4 pb-4 flex flex-row border-b border-divider">
				<div className="ml-8 mr-8 flex-row flex ">
					<div className="h-[56px] w-auto mr-2">
						{trackerOrganization?.organization.logoUrl && (
							<img
								alt={trackerOrganization?.organization.name}
								className="w-auto h-[56px]"
								src={trackerOrganization?.organization.logoUrl}
							/>
						)}
					</div>
					<div>
						<div className="text-md font-semibold">{trackerOrganization?.organization.name}</div>
						<div className="text-ssm font-medium">{trackerOrganization?.organization.industry}</div>
						<div className="text-ssm font-medium mb-3">
							{trackerOrganization?.organization.city}, {trackerOrganization?.organization.country}
						</div>
						{trackerOrganization?.organization.linkedinUrl && (
							<a
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2"
								href={trackerOrganization?.organization.linkedinUrl || ""}
								referrerPolicy="no-referrer"
								rel="noreferrer"
								target="_blank"
							>
								<img
									alt={trackerOrganization?.organization.name}
									className="w-auto h-[20px] m-2"
									src={getSvgIcons(CustomColumnTypes.LINKEDIN_POST)}
								/>
							</a>
						)}
						{trackerOrganization?.organization.phone && (
							<a
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2"
								href={`tel:${trackerOrganization?.organization.phone}` || ""}
								referrerPolicy="no-referrer"
								rel="noreferrer"
								target="_blank"
							>
								<SvgIcon className="w-auto h-[20px] m-2" svgIcon={PhoneIcon} />
							</a>
						)}
						{trackerOrganization?.organization.websiteUrl && (
							<a
								className="text-ssm font-medium border border-gray-200 inline-flex rounded-lg mr-2"
								href={trackerOrganization?.organization.websiteUrl || ""}
								referrerPolicy="no-referrer"
								rel="noreferrer"
								target="_blank"
							>
								<SvgIcon className="w-auto h-[20px] m-2" svgIcon={ExternalLinkIcon} />
							</a>
						)}
					</div>
				</div>
			</div>
			<div className="ml-8 mr-8 mb-8 flex flex-col">
				<TabContext value={currentTab}>
					<TabList aria-label="Account tabs" sx={{ mb: 2.5 }} onChange={handleTabsChange}>
						{tabs.map((tab, index) => (
							<Tab
								key={index * 2}
								label={
									<div
										className={classNames(
											"flex leading-[28px]",
											currentTab === tab.value ? " px-7" : ""
										)}
									>
										{tab.label}{" "}
									</div>
								}
								sx={{ py: 1.25 }}
								value={tab.value}
							/>
						))}
					</TabList>

					<TabPanel sx={{ p: 0, width: "100%" }} value={Tabs.ACTIVITY}>
						{activityDetails}
					</TabPanel>

					<TabPanel sx={{ p: 0, width: "100%" }} value={Tabs.PROFILES}>
						<div className="mb-8 flex flex-col items-end">
							<div className="max-w-[150px] mb-4">
								<Button
									size={ButtonSize.ML}
									title="Add Person"
									onClick={() => setShowSearchPerson(true)}
								/>
							</div>
							<div className="grid grid-cols-2 gap-4 w-full">
								{trackerOrganization?.trackerPersons?.map((person, index) => (
									<div
										key={index}
										className="flex flex-row border justify-between border-gray-200 rounded-xl w-full items-center"
									>
										<div className="flex flex-row w-full items-center">
											{person.prospect.image && (
												<img
													className="w-[48px] h-[48px] flex rounded-l-xl"
													src={person.prospect.image}
												/>
											)}
											<div className="flex flex-col ml-3">
												<span className="text-sm font-semibold">{person.prospect.name}</span>
												<span className="text-xs">{person.prospect.title}</span>
											</div>
											<div className="w-[60px] flex flex-col ml-3">
												{person.prospect.linkedinUrl && (
													<Link target="_blank" to={person.prospect.linkedinUrl}>
														<LinkedinIcon status="checked" />
													</Link>
												)}
											</div>
										</div>
										<div className="w-[60px]  flex flex-col ml-3">
											{person.prospect.linkedinUrl && (
												<button onClick={() => removePerson(person)}>
													<Icon className="opacity-[0.5] hober:opacity-1" icon={faCircleMinus} />
												</button>
											)}
										</div>
									</div>
								))}
							</div>
						</div>
					</TabPanel>
				</TabContext>
				{showSearchPerson && (
					<Modal
						handleClose={() => setShowSearchPerson(false)}
						handleSave={() => savePeople()}
						isLoading={isLoading}
						isOpened={showSearchPerson}
						title={"Search for person"}
					>
						<SearchField
							className="mb-4"
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								setSearchValue(event.target.value);
							}}
							name={"searchPerson"}
							placeholder="Search for a person"
							value={searchValue}
						/>

						{people?.people
							.filter((person) => {
								return !selectedPeople.includes(person);
							})
							?.map((person, index) => {
								return (
									<div
										key={`person-search-${index}`}
										className="w-full border border-gray-200 rounded-xl mb-4 mt-2 flex flex-row items-center"
									>
										<div className="flex items-center w-full">
											<img
												className="w-[48px] h-[48px] flex mr-3 rounded-l-xl"
												src={person.profilePicture}
											/>
											<div className="grow flex flex-col">
												<span className="text-sm font-semibold">{person.fullName}</span>
												<span className="text-xs">{person.primarySubtitle}</span>
											</div>
											<Checkbox
												className="!w-[20px] ml-4 mr-4"
												isChecked={selectedPeople.includes(person)}
												onChange={() => {
													if (selectedPeople.includes(person)) {
														setSelectedPeople(selectedPeople.filter((p) => p !== person));
													} else {
														setSelectedPeople([...selectedPeople, person]);
													}
												}}
											/>
										</div>
									</div>
								);
							})}
						{selectedPeople?.map((person, index) => {
							return (
								<div
									key={`person-search-${index}`}
									className="w-full border border-gray-200 rounded-xl mb-4 mt-2 flex flex-row items-center"
								>
									<div className="flex items-center w-full">
										<img
											className="w-[48px] h-[48px] flex mr-3 rounded-l-xl"
											src={person.profilePicture}
										/>
										<div className="grow flex flex-col">
											<span className="text-sm font-semibold">{person.fullName}</span>
											<span className="text-xs">{person.primarySubtitle}</span>
										</div>
										<Checkbox
											className="!w-[20px] ml-4 mr-4"
											isChecked={selectedPeople.includes(person)}
											onChange={() => {
												if (selectedPeople.includes(person)) {
													setSelectedPeople(selectedPeople.filter((p) => p !== person));
												} else {
													setSelectedPeople([...selectedPeople, person]);
												}
											}}
										/>
									</div>
								</div>
							);
						})}
						{getAllErrors(error, []).length ? renderErrorMessages(getAllErrors(error, [])) : null}
					</Modal>
				)}
				{showRemovePerson && (
					<Modal
						handleClose={() => setShowRemovePerson(false)}
						handleSave={handleRemovePersonFromTrackerOrganization}
						isLoading={removePersonIsLoading}
						isOpened={showRemovePerson}
						submitButtonColor="error"
						submitButtonText={t("basics.delete")}
						title={ts("delete.title")}
					>
						<div className="text-sm text-gray-700 mb-4">{ts("delete.confirmation")}</div>
						<TextField
							fullWidth
							defaultValue={selectedTrackerPerson?.name}
							inputProps={{ readOnly: true }}
							label={ts("name")}
						/>
						{getAllErrors(errorRemoving, []).length
							? renderErrorMessages(getAllErrors(errorRemoving, []))
							: null}
					</Modal>
				)}
			</div>
		</div>,
		document.body
	);
};
