import { z } from "zod";

import { CustomColumnFrequency, CustomColumnTypes } from "@/enum/list.enum";
import { MetaDataSchema } from "@/schemas/meta-data";

export const ColumnConfigSchema = z.object({
	id: z.number().optional(),
	listId: z.number().optional(),
	trackerId: z.number().optional(),
	name: z.string(),
	type: z.nativeEnum(CustomColumnTypes),
	config: z.any(),
	frequency: z.nativeEnum(CustomColumnFrequency).optional(),
	updateOrOnce: z.boolean().optional(),
	score: z.number().optional().nullable(),
	sumUpScoreOverTime: z.boolean().optional(),
	sourceConfig: z.any(),
	saveAsTemplate: z.boolean().optional(),
});

export const ColumnConfigResponseSchema = z.object({
	data: z.array(ColumnConfigSchema),
	meta: MetaDataSchema,
});

export type ColumnConfig = z.infer<typeof ColumnConfigSchema>;

export type ColumnConfigResponse = z.infer<typeof ColumnConfigResponseSchema>;
