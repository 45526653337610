/* eslint-disable @typescript-eslint/no-explicit-any */
import { faFolder, faCoin } from "@fortawesome/pro-regular-svg-icons";
import { Autocomplete, TextField } from "@mui/material";

import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Transforms, Editor, BaseEditor, Descendant } from "slate";

import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";

import suggestionsData from "@/pages/Private/pages/ProfileTracker/utils/suggestions";

import { Icon, Modal } from "@/components";
import { useHasPermissions } from "@/utils/useHasPermissions";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import PromptEditor from "@/pages/Private/pages/List/components/PromptEditor";
import { AiApplyType, CustomColumnFrequency, CustomColumnTypes } from "@/enum/list.enum";
import { PermissionRoles } from "@/enum";
import { TextArea } from "@/components/TextArea/TextArea";
import { InputField } from "@/components/InputField/InputField";
import { Dropdown, AutoCompleteItem } from "@/components/Dropdown/Dropdown";
import { ButtonSize, ButtonColor } from "@/components/Button/types";
import { profileTrackerSelector } from "@/pages/Private/redux/profileTracker/profileTracker.slice";
import { useAppSelector } from "@/redux/hooks";
import {
	useAddColumnToTrackerMutation,
	useRunAiPromptMutation,
} from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { Button } from "@/components/Button/Button";

import { Radio } from "@/components/Radio/Radio";
import { CustomColumn, CustomColumnSchema } from "@/pages/Private/pages/List/schema/list";

import { ColumnConfig } from "@/pages/Private/pages/List/schema/columnConfig";

import { Toggle } from "@/components/Toggle/Toggle";

import { getSuggestions, replaceWithRealValues } from "../../../utils/colum-utils";
import { useOrganizationSlider } from "../../../utils/useOrganizationSlider";

export interface AiModalProps {
	setShowConfirm: (value: boolean) => void;
	open: boolean;
	trackerId: number;
	setOpen: (value: boolean) => void;
	columnId?: number;
	column?: ColumnConfig;
}

export const AiModal: FunctionComponent<AiModalProps> = ({
	setShowConfirm,
	trackerId,
	open,
	setOpen,
	columnId,
	column,
}) => {
	const { hasPermissions } = useHasPermissions();
	const { records } = useAppSelector(profileTrackerSelector);
	const [editorValue, setEditorValue] = useState<Descendant[]>();
	const [editor, setEditor] = useState<BaseEditor>();
	const [aiResult, setAiResult] = useState<string>();

	const [frequency, setFrequency] = useState<CustomColumnFrequency>();
	const [sumUpScoreOverTime, setSumUpScoreOverTime] = useState<boolean>(false);

	const { currentOrganization, renderOrganizationSlider, currentPerson, renderPersonSlider } =
		useOrganizationSlider();

	const [selectedVariable, setSelectedVariable] = useState<
		{ id: string; name: string } | undefined
	>();

	const [updateOrOnce, setUpdateOrOnce] = useState<boolean>(false);
	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`aiColumn.${key}`), [t]);

	const [update, { isLoading, error }] = useAddColumnToTrackerMutation();
	const [runPrompt, { isLoading: promptIsLoading }] = useRunAiPromptMutation();

	const suggestions = useMemo(() => {
		return !!records[currentOrganization]
			? getSuggestions(
					records[currentOrganization],
					records[currentOrganization]?.trackerPersons?.[currentPerson]
			  )
			: [];
	}, [currentOrganization, currentPerson, records]);

	const getSuggestionLabel = (key: string) => {
		return suggestionsData.suggestions[key as keyof typeof suggestionsData.suggestions] as string;
	};

	const {
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CustomColumn>({
		defaultValues: {
			customColumnType: CustomColumnTypes.AI_PROMPT,
			...column,
			customColumnName: column?.name,
			value: column?.config,
		},
		resolver: zodResolver(CustomColumnSchema),
	});

	const handleRunPrompt = async () => {
		const result = await runPrompt({
			id: records[currentOrganization].id,
			prompt: replaceWithRealValues({
				value: editorValue,
				inboxItem: records[currentOrganization],
				suggestions,
				person: records[currentOrganization]?.trackerPersons?.[currentPerson],
			}),
			online: getValues("online"),
		}).unwrap();

		setAiResult(result.data);
	};

	useEffect(() => {
		if (column) {
			setFrequency(column.frequency);

			if (column.updateOrOnce !== undefined) {
				setUpdateOrOnce(column.updateOrOnce);
			}

			if (column.sumUpScoreOverTime !== undefined) {
				setSumUpScoreOverTime(column.sumUpScoreOverTime);
			}

			setEditorValue(JSON.parse(column.config));
			setEditor(JSON.parse(column.config));
		}
	}, [column]);

	const serialize = (nodes: any) => {
		return JSON.stringify(nodes);
	};

	const onSubmitSaveOnly = async (values: CustomColumn) => {
		try {
			values.value = editorValue ? serialize(editorValue) : "";

			await update({
				...values,
				aiApplyType: AiApplyType.NONE,
				id: trackerId,
				trackerOrganizationIds: records.slice(0, 10).map((p) => p.id),
				updateOrOnce,
				sumUpScoreOverTime,
				frequency,
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const onSubmit = async (values: CustomColumn) => {
		try {
			values.value = editorValue ? serialize(editorValue) : "";

			await update({
				...values,
				id: trackerId,
				updateOrOnce,
				sumUpScoreOverTime,
				frequency,
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const onSubmitFirst10 = async (values: CustomColumn) => {
		try {
			values.value = editorValue ? serialize(editorValue) : "";

			await update({
				...values,
				aiApplyType: AiApplyType.FIRST_10,
				id: trackerId,
				trackerOrganizationIds: records.slice(0, 10).map((p) => p.id),
				updateOrOnce,
				sumUpScoreOverTime,
				frequency,
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<Modal
			handleClose={() => {
				setShowConfirm(true);
			}}
			handleSave={handleSubmit(onSubmit)}
			handleSecondSave={handleSubmit(onSubmitSaveOnly)}
			handleThirdSave={handleSubmit(onSubmitFirst10)}
			isLoading={isLoading}
			isOpened={open}
			secondSubmitButtonText={t("basics.save")}
			size="lg"
			submitButtonText={ts("applyToAll")}
			thirdSubmitButtonText={ts("applyTo10")}
			title="Create AI Column"
		>
			<div className="text-ssm font-medium mb-2">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setValue("customColumnName", event.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					label="Column Name"
					name={"name"}
					placeholder="Enter column name"
					value={getValues("customColumnName")}
				/>
			</div>

			<div className="flex flex-col items-start">
				<div className="flex flex-row justify-between w-full">
					<div className="text-ssm font-medium mt-2 mb-1">Prompt editor</div>
					<div className="flex flex-row gap-2">
						<div className="text-ssm font-medium mt-2 mb-1 flex items-center">
							<Toggle
								isChecked={getValues("online") || false}
								onChange={() => {
									setValue("online", !getValues("online"), {
										shouldValidate: true,
										shouldDirty: true,
									});
								}}
							/>{" "}
							<span className="ml-2">Activate web search</span>
						</div>
					</div>
				</div>

				<div className="flex relative flex-col w-full border border-gray-300 rounded-xl">
					<div className="w-full min-h-[120px] ">
						<PromptEditor
							getSuggestionLabel={getSuggestionLabel}
							handleChange={(value) => {
								setEditor(value);
							}}
							handleValueChange={(value) => {
								setEditorValue(value);
							}}
							suggestions={suggestions}
							value={column ? JSON.parse(column.config) : undefined}
						/>
					</div>
					<div className="p-2 w-full flex border-t border-gray-300 justify-between items-center">
						<div className="flex items-center">
							<div className="mr-2 text-sm text-gray-700">Select</div>
							<Autocomplete
								getOptionLabel={(option: { name: string; id: string }) => option.name}
								id="event"
								options={
									suggestions
										?.filter((key) => {
											const label = getSuggestionLabel(key);

											return label && label !== key;
										})
										?.map((key) => ({
											name: getSuggestionLabel(key) as string,
											id: key,
										})) || []
								}
								renderInput={(params) => {
									return (
										<TextField
											// sx={{
											// 	height: "40px",
											// }}
											{...params}
											placeholder="Variable"
										/>
									);
								}}
								size="small"
								sx={{
									width: "300px",
									"& .MuiFilledInput-root": {
										paddingTop: "4px!important",
										paddingBottom: "4px!important",
										marginBottom: "0px!important",
									},
									"&.MuiFormControl-root": {
										height: "40px",
									},
								}}
								value={selectedVariable}
								onChange={(event, value) => {
									if (value && editor) {
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										const [firstChild, ...otherChildren] = editor.children as any;

										const newChildren = [
											...(firstChild.children || []),
											{
												type: "mention",
												character: `${value.id}`,
												children: [
													{
														text: ``,
													},
												],
											},
											{
												text: ``,
											},
										];

										const newVal = [{ children: newChildren }, ...otherChildren];

										Transforms.delete(editor as any, {
											at: {
												anchor: Editor.start(editor as any, []),
												focus: Editor.end(editor as any, []),
											},
										});

										Transforms.removeNodes(editor as any, {
											at: [0],
										});

										// Insert array of children nodes
										Transforms.insertNodes(editor as any, newVal);

										setSelectedVariable(undefined);
									}
								}}
							/>
							<div className="ml-2 text-sm text-gray-700">
								or enter{" "}
								<span className="bg-inactive-item px-1 border border-gray-200 rounded-md">
									{"{"}
								</span>{" "}
								to insert variable
							</div>
						</div>
						<div className="w-1/2 flex justify-end">
							<div className="w-1/2 mr-2 max-w-[150px]">
								<Button
									color={ButtonColor.DISABLED}
									disabled={true}
									image={<Icon className="mr-2" icon={faFolder} />}
									size={ButtonSize.S}
									title="Prompt library"
								/>
							</div>
							<div className="w-1/2 max-w-[110px]">
								<Button
									disabled={false}
									isLoading={promptIsLoading}
									size={ButtonSize.S}
									title="Run prompt"
									onClick={handleRunPrompt}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-row">
				<div className="flex flex-col grow mr-2">
					<div className="text-ssm font-medium mt-4 mb-2">Prompt preview</div>
					<TextArea
						className="!mb-0 grow mr-4 max-h-[192px]"
						name={"randomValue"}
						placeholder="Enter value"
						showError={false}
						value={
							replaceWithRealValues({
								value: editorValue,
								inboxItem: records[currentOrganization],
								suggestions,
								person: records[currentOrganization]?.trackerPersons?.[currentPerson],
							}) || ""
						}
					/>
				</div>
				<div className="flex flex-col grow ml-2">
					<div className="text-ssm font-medium mt-4 mb-2">Prompt result preview</div>
					<TextArea
						className="!mb-0 grow mr-4 max-h-[192px]"
						name={"randomValue"}
						showError={false}
						value={aiResult || ""}
					/>
				</div>
			</div>
			{renderPersonSlider(setAiResult, records)}

			{renderOrganizationSlider(setAiResult, records)}
			<div className="flex flex-col mt-5 border-t pt-5 border-gray-200">
				<div className="flex  gap-4 flex-col text-gray-700 items-center">
					<Dropdown
						data={[
							{
								title: ts(CustomColumnFrequency.ONCE),
								id: CustomColumnFrequency.ONCE,
							},
							{
								title: ts(CustomColumnFrequency.WEEKLY),
								id: CustomColumnFrequency.WEEKLY,
							},
							{
								title: ts(CustomColumnFrequency.MONTHLY),
								id: CustomColumnFrequency.MONTHLY,
							},
							{
								title: ts(CustomColumnFrequency.QUATERLY),
								id: CustomColumnFrequency.QUATERLY,
							},
							{
								title: ts(CustomColumnFrequency.HALFYEARLY),
								id: CustomColumnFrequency.HALFYEARLY,
							},
							{
								title: ts(CustomColumnFrequency.YEARLY),
								id: CustomColumnFrequency.YEARLY,
							},
						]}
						defaultValue={
							frequency && {
								id: frequency,
								title: ts(frequency),
							}
						}
						floating={true}
						handleSelect={function (value?: AutoCompleteItem) {
							if (value?.id) {
								setValue("frequency", value.id as CustomColumnFrequency);
								setFrequency(value.id as CustomColumnFrequency);
							}
						}}
						label="Execution frequency"
					/>
				</div>
				<div className="flex flex-col grow mr-2">
					<div className="text-ssm font-medium mt-4 mb-2">Update frequency</div>
					<div className="flex flex-col gap-4">
						<Radio
							className="whitespace-nowrap"
							isChecked={updateOrOnce}
							onChange={() => {
								setUpdateOrOnce(!updateOrOnce);
							}}
						>
							{ts("updateOnEachExecution")}
						</Radio>

						<Radio
							className="whitespace-nowrap"
							isChecked={!updateOrOnce}
							onChange={() => {
								setUpdateOrOnce(!updateOrOnce);
							}}
						>
							{ts("onceAfterFindingAValue")}
						</Radio>
					</div>
				</div>
			</div>
			<div className="flex flex-col mt-5 border-t pt-5 border-gray-200">
				<div className="flex flex-col grow mr-2">
					<div className="text-ssm font-medium mb-2">Scoring</div>
					<div className="flex flex-col gap-4">
						<InputField
							handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
								setValue("score", +event.target.value, {
									shouldValidate: true,
									shouldDirty: true,
								});
							}}
							name={"score"}
							placeholder="Enter score"
							type="number"
							value={getValues("score")?.toString() || ""}
						/>
					</div>
				</div>
				<div className="flex flex-col grow mr-2">
					<div className="text-ssm font-medium mt-4 mb-2">Sum up score over time</div>
					<div className="flex flex-col gap-4">
						<Radio
							className="whitespace-nowrap"
							isChecked={sumUpScoreOverTime}
							onChange={() => {
								setSumUpScoreOverTime(!sumUpScoreOverTime);
							}}
						>
							{ts("sumUpScoreOverTime")}
						</Radio>

						<Radio
							className="whitespace-nowrap"
							isChecked={!sumUpScoreOverTime}
							onChange={() => {
								setSumUpScoreOverTime(!sumUpScoreOverTime);
							}}
						>
							{ts("doNotSumUpScoreOverTime")}
						</Radio>
					</div>
				</div>
			</div>
			{hasPermissions([PermissionRoles.GLOBAL_ADMIN]) && (
				<div className="flex flex-row mt-5 border-t pt-5 border-gray-200 text-gray-700 items-center text-sm">
					<span className="mr-1">{getValues("online") ? "3" : "1"}x</span>
					<Icon className="w-[16px] h-[16px] text-gray-700 mr-1" icon={faCoin} />
					<span className="">per row</span>
				</div>
			)}
			{getAllErrors(error, formErrors).length ? (
				<div className="mt-4 mb-0">{renderErrorMessages(getAllErrors(error, formErrors))}</div>
			) : null}
		</Modal>
	);
};
