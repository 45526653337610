import { ListSourceType } from "@/enum/list.enum";
import { ReactComponent as CsvEnrichment } from "@assets/icons/create-list/csvEnrichment.svg";
import { ReactComponent as GoogleMaps } from "@assets/icons/create-list/googleMaps.svg";
import { ReactComponent as Jobs } from "@assets/icons/create-list/jobs.svg";
import { ReactComponent as LiEvents } from "@assets/icons/create-list/liEvents.svg";
import { ReactComponent as LinkedinSearch } from "@assets/icons/create-list/linkedinSearch.svg";
import { ReactComponent as PeopleSearch } from "@assets/icons/create-list/peopleSearch.svg";
import { ReactComponent as PostByKeyword } from "@assets/icons/create-list/postByKeyword.svg";
import { ReactComponent as ProfileVisitors } from "@assets/icons/create-list/profileVisitors.svg";

export const createNewListSettings: {
	title: string;
	items: {
		listType: ListSourceType;
		title: string;
		description: string;
		note?: string;
		icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
		disabled?: boolean;
	}[];
}[] = [
	{
		title: "General",
		items: [
			{
				listType: ListSourceType.CUSTOM_EVENTS,
				title: "Offline Event",
				description: "Find out who attends an event based on networking apps.",
				icon: LiEvents,
				disabled: false,
			},
			{
				listType: ListSourceType.CSV_ENRICHMENTS,
				title: "XLSX Upload",
				description: "Enrich data from the uploaded XLSX data.",
				icon: CsvEnrichment,
			},
		],
	},
	{
		title: "LinkedIn",
		items: [
			{
				listType: ListSourceType.LI_EVENTS,
				title: "LinkedIn Events",
				description: "Search for attendees of LinkedIn webinars.",
				icon: LiEvents,
				disabled: false,
			},
			{
				listType: ListSourceType.LI_PROFILE_VISITS,
				title: "Profile visitors",
				description: "Find out who visits your LinkedIn profile.",
				icon: ProfileVisitors,
				disabled: false,
			},
			{
				listType: ListSourceType.LI_POST,
				title: "Linkedin Engagement",
				description:
					"Find out when someone posts about a topic by keyword or who likes or comments on posts.",
				icon: PostByKeyword,
				disabled: false,
			},
		],
	},
	{
		title: "Peole search",
		items: [
			{
				listType: ListSourceType.PROSPECT_SEARCH,
				title: "Apollo People search",
				description: "Search for titles and company characteristics.",
				note: "You need to have an Apollo API key to use this feature.",
				icon: PeopleSearch,
				disabled: true,
			},
			{
				listType: ListSourceType.LI_PEOPLE,
				title: "LinkedIn Search",
				description: "Perform a search to find individuals on LinkedIn.",
				icon: LinkedinSearch,
				disabled: false,
			},
		],
	},
	{
		title: "Google Maps",
		items: [
			{
				listType: ListSourceType.MAPS,
				title: "Google Maps",
				description: "Search and identify companies listed on Google Maps.",
				icon: GoogleMaps,
			},
		],
	},
	{
		title: "Jobs",
		items: [
			{
				listType: ListSourceType.JOBS,
				title: "Jobs",
				description: "Find out who posts job offers by keyword",
				icon: Jobs,
			},
		],
	},
];
