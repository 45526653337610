import { Dispatch, SetStateAction, useState } from "react";

import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";

import { ButtonColor, ButtonSize } from "@/components/Button/types";
import { Button } from "@/components/Button/Button";
import { Icon } from "@/components";

import { TrackerOrganization } from "../schema/trackerOrganizations";

export const useOrganizationSlider = () => {
	const [currentOrganization, setCurrentOrganization] = useState<number>(0);

	const handlePreviousOrganization = (
		setAiResult: Dispatch<SetStateAction<string | undefined>>
	) => {
		if (currentOrganization > 0) {
			setCurrentOrganization(currentOrganization - 1);
			setCurrentPerson(0);
			setAiResult("");
		}
	};

	const handleNextOrganization = (
		setAiResult: Dispatch<SetStateAction<string | undefined>>,
		records: TrackerOrganization[]
	) => {
		if (currentOrganization < records.length - 1) {
			setCurrentOrganization(currentOrganization + 1);
			setCurrentPerson(0);
			setAiResult("");
		}
	};

	const [currentPerson, setCurrentPerson] = useState<number>(0);

	const handlePreviousPerson = (setAiResult: Dispatch<SetStateAction<string | undefined>>) => {
		if (currentPerson > 0) {
			setCurrentPerson(currentPerson - 1);
			setAiResult("");
		}
	};

	const handleNextPerson = (
		setAiResult: Dispatch<SetStateAction<string | undefined>>,
		currentOrganization: number,
		records: TrackerOrganization[]
	) => {
		if (records?.[currentOrganization]?.trackerPersons?.[currentPerson + 1]) {
			setCurrentPerson(currentPerson + 1);
			setAiResult("");
		}
	};

	const renderPersonSlider = (
		setAiResult: Dispatch<SetStateAction<string | undefined>>,
		records: TrackerOrganization[]
	) => {
		return (
			<div className="flex flex-row mt-5 border-t pt-5 border-gray-200">
				<div className="w-1/2 mr-2 grow">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						disabled={currentPerson === 0}
						image={<Icon className="mr-2" icon={faArrowLeft} />}
						size={ButtonSize.S}
						title="Previous Person"
						onClick={() => handlePreviousPerson(setAiResult)}
					/>
				</div>
				<div className="w-1/2 ml-2 grow">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						disabled={!records?.[currentOrganization]?.trackerPersons?.[currentPerson + 1]}
						iconRight={true}
						image={<Icon className="ml-2" icon={faArrowRight} />}
						size={ButtonSize.S}
						title="Next Person"
						onClick={() => handleNextPerson(setAiResult, currentOrganization, records)}
					/>
				</div>
			</div>
		);
	};

	const renderOrganizationSlider = (
		setAiResult: Dispatch<SetStateAction<string | undefined>>,
		records: TrackerOrganization[]
	) => {
		return (
			<div className="flex flex-row mt-5 border-t pt-5 border-gray-200">
				<div className="w-1/2 mr-2 grow">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						disabled={currentOrganization === 0}
						image={<Icon className="mr-2" icon={faArrowLeft} />}
						size={ButtonSize.S}
						title="Previous Organization"
						onClick={() => handlePreviousOrganization(setAiResult)}
					/>
				</div>
				<div className="w-1/2 ml-2 grow">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						disabled={currentOrganization === records.length - 1}
						iconRight={true}
						image={<Icon className="ml-2" icon={faArrowRight} />}
						size={ButtonSize.S}
						title="Next Organization"
						onClick={() => handleNextOrganization(setAiResult, records)}
					/>
				</div>
			</div>
		);
	};

	return {
		currentOrganization,
		setCurrentOrganization,
		handlePreviousOrganization,
		handleNextOrganization,
		handleNextPerson,
		currentPerson,
		setCurrentPerson,
		handlePreviousPerson,
		renderOrganizationSlider,
		renderPersonSlider,
	};
};
