/* eslint-disable @typescript-eslint/no-explicit-any */
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import { ChangeEvent, FunctionComponent, useState } from "react";

import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";

import trashIcon from "@/assets/icons/trashIcon.svg";

import { Icon, Modal } from "@/components";
import { InputField } from "@/components/InputField/InputField";
import { ButtonSize } from "@/components/Button/types";
import { useAddColumnToTrackerMutation } from "@/pages/Private/redux/profileTracker/profileTracker.api";

import { Button } from "@/components/Button/Button";

import { CustomColumn, CustomColumnSchema } from "@/pages/Private/pages/List/schema/list";
import { CustomColumnTypes } from "@/enum/list.enum";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import { ColumnConfig } from "@/pages/Private/pages/List/schema/columnConfig";

export interface RandomValueModalProps {
	setShowConfirm: (value: boolean) => void;
	open: boolean;
	trackerId: number;
	setOpen: (value: boolean) => void;
	columnId?: number;
	column?: ColumnConfig;
}

export const RandomValueModal: FunctionComponent<RandomValueModalProps> = ({
	setShowConfirm,
	trackerId,
	open,
	setOpen,
	columnId,
	column,
}) => {
	const [randomValues, setRandomValues] = useState<string[]>(
		column?.config ? (column.config as string[]) : []
	);
	const [randomValue, setRandomValue] = useState<string>();

	const { t } = useTranslation();

	const [update, { isLoading, error }] = useAddColumnToTrackerMutation();

	const {
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CustomColumn>({
		defaultValues: {
			customColumnType: CustomColumnTypes.RANDOM_VALUE,
			...column,
			customColumnName: column?.name,
		},
		resolver: zodResolver(CustomColumnSchema),
	});

	const onSubmit = async (values: CustomColumn) => {
		try {
			values.value = randomValues;

			await update({
				id: trackerId,
				columnId,
				...values,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	return (
		<Modal
			disableSave={randomValues.length === 0}
			handleClose={() => {
				setShowConfirm(true);
			}}
			handleSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isOpened={open}
			size="md"
			submitButtonText={t("basics.confirm")}
			title="Create Random Value Column"
		>
			<div className="text-ssm font-medium mb-4">
				<InputField
					error={!!errors.customColumnName}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setValue("customColumnName", event.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					label="Column Name"
					name={"name"}
					placeholder="Enter column name"
					value={getValues("customColumnName")}
				/>
			</div>

			<div className="flex flex-row items-start space-y-4">
				<InputField
					className="!mb-0 grow mr-4"
					error={!!errors.value}
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setRandomValue(event.target.value);
					}}
					name={"randomValue"}
					placeholder="Enter value"
					value={randomValue ?? ""}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							if (randomValue) {
								setRandomValues([randomValue, ...(randomValues as string[])]);
							}

							setRandomValue("");
						}
					}}
				/>
				<div className="w-[140px] !mt-0 ml-4 flex items-start">
					<Button
						className="!mt-0"
						disabled={!randomValue}
						image={<Icon className="mr-2" icon={faPlus} />}
						size={ButtonSize.L}
						title="Add value"
						onClick={() => {
							setRandomValues([...(randomValues as string[]), randomValue ?? ""]);
							setRandomValue("");
						}}
					/>
				</div>
			</div>
			<div>
				{randomValues.length > 0 && <div className="text-ssm font-medium mt-4 mb-2">Values</div>}
				{(randomValues as string[]).map((value, index) => (
					<div
						key={index}
						className="flex items-center justify-between h-[54px] px-2.5 py-4.5 rounded-xl border border-gray-200 mb-4"
					>
						<div>{value}</div>
						<button
							className="cursor-pointer"
							onClick={() => {
								const values = [...(randomValues as string[])];

								values.splice(index, 1);
								setRandomValues(values);
							}}
						>
							<img alt="trash" src={trashIcon} />
						</button>
					</div>
				))}
			</div>
			{getAllErrors(error, formErrors).length ? (
				<div className="mt-4 mb-0">{renderErrorMessages(getAllErrors(error, formErrors))}</div>
			) : null}
		</Modal>
	);
};
