/* eslint-disable @typescript-eslint/no-explicit-any */
import { faFolder, faArrowLeft, faArrowRight, faCoin } from "@fortawesome/pro-regular-svg-icons";
import { Autocomplete, TextField } from "@mui/material";

import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Transforms, Editor, BaseEditor, Descendant } from "slate";

import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";

import { Icon, Modal } from "@/components";
import { useHasPermissions } from "@/utils/useHasPermissions";
import { ERROR_TYPE, getAllErrors, renderErrorMessages } from "@/utils";
import PromptEditor from "@/pages/Private/pages/List/components/PromptEditor";
import { AiApplyType, CustomColumnTypes, UpdateType } from "@/enum/list.enum";
import { PermissionRoles } from "@/enum";
import { TextArea } from "@/components/TextArea/TextArea";
import { InputField } from "@/components/InputField/InputField";
import { ButtonSize, ButtonColor } from "@/components/Button/types";
import { useAppSelector } from "@/redux/hooks";

import suggestionsData from "@/utils/suggestions";
import { Button } from "@/components/Button/Button";

import { CustomColumn, CustomColumnSchema } from "@/pages/Private/pages/List/schema/list";

import { ColumnConfig } from "@/pages/Private/pages/List/schema/columnConfig";

import { Toggle } from "@/components/Toggle/Toggle";

import { listSelector } from "@/pages/Private/redux/list/list.slice";

import {
	useRunAiEnrichmentMutation,
	useUpdateListColumnsMutation,
} from "@/pages/Private/redux/list/list.api";

import { getSuggestions, replaceWithRealValues } from "../../utils/colum-utils";

export interface AiModalProps {
	setShowConfirm: (value: boolean) => void;
	open: boolean;
	listId: number;
	setOpen: (value: boolean) => void;
	columnId?: number;
	column?: ColumnConfig;
}

export const AiModal: FunctionComponent<AiModalProps> = ({
	setShowConfirm,
	listId,
	open,
	setOpen,
	columnId,
	column,
}) => {
	const { hasPermissions } = useHasPermissions();

	const [editorValue, setEditorValue] = useState<Descendant[]>();
	const [editor, setEditor] = useState<BaseEditor>();
	const [aiResult, setAiResult] = useState<string>();
	const [saveAsTemplate, setSaveAsTemplate] = useState<boolean>(false);

	const [currentLead, setCurrentLead] = useState<number>(0);

	const [selectedVariable, setSelectedVariable] = useState<
		{ id: string; name: string } | undefined
	>();

	const handlePreviousLead = () => {
		if (currentLead > 0) {
			setCurrentLead(currentLead - 1);
			setAiResult("");
		}
	};

	const handleNextLead = () => {
		if (currentLead < inbox.length - 1) {
			setCurrentLead(currentLead + 1);
			setAiResult("");
		}
	};

	const { t } = useTranslation();
	const ts = useCallback((key: string) => t(`aiColumn.${key}`), [t]);

	const [update, { isLoading, error }] = useUpdateListColumnsMutation();
	const [runPrompt, { isLoading: promptIsLoading }] = useRunAiEnrichmentMutation();

	const { inbox } = useAppSelector(listSelector);

	const suggestions = useMemo(() => {
		return !!inbox[currentLead] ? getSuggestions(inbox[currentLead]) : [];
	}, [currentLead, inbox]);

	const getSuggestionLabel = (key: string) => {
		return suggestionsData.suggestions[key as keyof typeof suggestionsData.suggestions] as string;
	};

	const {
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm<CustomColumn>({
		defaultValues: {
			customColumnType: CustomColumnTypes.AI_PROMPT,
			online: false,
			...column,
			customColumnName: column?.name,
			value: column?.config,
			updateOrOnce: undefined,
			frequency: undefined,
		},
		resolver: zodResolver(CustomColumnSchema),
	});

	const handleRunPrompt = async () => {
		const result = await runPrompt({
			id: inbox[currentLead].id,
			prompt: replaceWithRealValues(editorValue, inbox[currentLead], suggestions),
			online: getValues("online") || false,
		}).unwrap();

		setAiResult(result.data);
	};

	useEffect(() => {
		if (column) {
			setEditorValue(JSON.parse(column.config));
			setEditor(JSON.parse(column.config));
		}
	}, [column]);

	const serialize = (nodes: any) => {
		return JSON.stringify(nodes);
	};

	const onSubmitSaveOnly = async (values: CustomColumn) => {
		try {
			values.value = editorValue ? serialize(editorValue) : "";

			await update({
				...values,
				updateType: UpdateType.CUSTOM_COLUMN,
				saveAsTemplate,
				aiApplyType: AiApplyType.NONE,
				id: listId,
				prospectIds: inbox.slice(0, 10).map((p) => p.id),
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const onSubmit = async (values: CustomColumn) => {
		try {
			values.value = editorValue ? serialize(editorValue) : "";

			await update({
				...values,
				updateType: UpdateType.CUSTOM_COLUMN,
				saveAsTemplate,
				id: listId,
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const onSubmitFirst10 = async (values: CustomColumn) => {
		try {
			values.value = editorValue ? serialize(editorValue) : "";

			await update({
				...values,
				saveAsTemplate,
				updateType: UpdateType.CUSTOM_COLUMN,
				aiApplyType: AiApplyType.FIRST_10,
				id: listId,
				prospectIds: inbox.slice(0, 10).map((p) => p.id),
				columnId,
			}).unwrap();

			setOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	const formErrors = Object.values(errors).map((error) => error?.message) as ERROR_TYPE[];

	console.log(errors);

	return (
		<Modal
			checkbox={saveAsTemplate}
			checkboxLabel="Save as template"
			handleCheckboxClick={() => setSaveAsTemplate(!saveAsTemplate)}
			handleClose={() => {
				setShowConfirm(true);
			}}
			handleSave={handleSubmit(onSubmit)}
			handleSecondSave={handleSubmit(onSubmitSaveOnly)}
			handleThirdSave={handleSubmit(onSubmitFirst10)}
			isLoading={isLoading}
			isOpened={open}
			secondSubmitButtonText={t("basics.save")}
			size="lg"
			submitButtonText={ts("applyToAll")}
			thirdSubmitButtonText={ts("applyTo10")}
			title="Create AI Column"
		>
			<div className="text-ssm font-medium mb-2">
				<InputField
					handleChange={function (event: ChangeEvent<HTMLInputElement>): void {
						setValue("customColumnName", event.target.value, {
							shouldValidate: true,
							shouldDirty: true,
						});
					}}
					label="Column Name"
					name={"name"}
					placeholder="Enter column name"
					value={getValues("customColumnName")}
				/>
			</div>

			<div className="flex flex-col items-start">
				<div className="flex flex-row justify-between w-full">
					<div className="text-ssm font-medium mt-2 mb-1">Prompt editor</div>
					<div className="flex flex-row gap-2">
						<div className="text-ssm font-medium mt-2 mb-1 flex items-center">
							<Toggle
								isChecked={getValues("online") || false}
								onChange={() => {
									setValue("online", !getValues("online"), {
										shouldValidate: true,
										shouldDirty: true,
									});
								}}
							/>{" "}
							<span className="ml-2">Activate web search</span>
						</div>
					</div>
				</div>

				<div className="flex relative flex-col w-full border border-gray-300 rounded-xl">
					<div className="w-full min-h-[120px] ">
						<PromptEditor
							getSuggestionLabel={getSuggestionLabel}
							handleChange={(value) => {
								setEditor(value);
							}}
							handleValueChange={(value) => {
								setEditorValue(value);
							}}
							suggestions={suggestions}
							value={column ? JSON.parse(column.config) : undefined}
						/>
					</div>
					<div className="p-2 w-full flex border-t border-gray-300 justify-between items-center">
						<div className="flex items-center">
							<div className="mr-2 text-sm text-gray-700">Select</div>
							<Autocomplete
								getOptionLabel={(option: { name: string; id: string }) => option.name}
								id="event"
								options={
									suggestions
										?.filter((key) => {
											const label = getSuggestionLabel(key);

											return label && label !== key;
										})
										?.map((key) => ({
											name: getSuggestionLabel(key) as string,
											id: key,
										})) || []
								}
								renderInput={(params) => {
									return (
										<TextField
											// sx={{
											// 	height: "40px",
											// }}
											{...params}
											placeholder="Variable"
										/>
									);
								}}
								size="small"
								sx={{
									width: "300px",
									"& .MuiFilledInput-root": {
										paddingTop: "4px!important",
										paddingBottom: "4px!important",
										marginBottom: "0px!important",
									},
									"&.MuiFormControl-root": {
										height: "40px",
									},
								}}
								value={selectedVariable}
								onChange={(event, value) => {
									if (value && editor) {
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										const [firstChild, ...otherChildren] = editor.children as any;

										const newChildren = [
											...(firstChild.children || []),
											{
												type: "mention",
												character: `${value.id}`,
												children: [
													{
														text: ``,
													},
												],
											},
											{
												text: ``,
											},
										];

										const newVal = [{ children: newChildren }, ...otherChildren];

										Transforms.delete(editor as any, {
											at: {
												anchor: Editor.start(editor as any, []),
												focus: Editor.end(editor as any, []),
											},
										});

										Transforms.removeNodes(editor as any, {
											at: [0],
										});

										// Insert array of children nodes
										Transforms.insertNodes(editor as any, newVal);

										setSelectedVariable(undefined);
									}
								}}
							/>
							<div className="ml-2 text-sm text-gray-700">
								or enter{" "}
								<span className="bg-inactive-item px-1 border border-gray-200 rounded-md">
									{"{"}
								</span>{" "}
								to insert variable
							</div>
						</div>
						<div className="w-1/2 flex justify-end">
							<div className="w-1/2 mr-2 max-w-[150px]">
								<Button
									color={ButtonColor.DISABLED}
									disabled={true}
									image={<Icon className="mr-2" icon={faFolder} />}
									size={ButtonSize.S}
									title="Prompt library"
								/>
							</div>
							<div className="w-1/2 max-w-[110px]">
								<Button
									disabled={false}
									isLoading={promptIsLoading}
									size={ButtonSize.S}
									title="Run prompt"
									onClick={handleRunPrompt}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="flex flex-row">
				<div className="flex flex-col grow mr-2">
					<div className="text-ssm font-medium mt-4 mb-2">Prompt preview</div>
					<TextArea
						className="!mb-0 grow mr-4 max-h-[192px]"
						name={"randomValue"}
						placeholder="Enter value"
						showError={false}
						value={replaceWithRealValues(editorValue, inbox[currentLead], suggestions) || ""}
					/>
				</div>
				<div className="flex flex-col grow ml-2">
					<div className="text-ssm font-medium mt-4 mb-2">Prompt result preview</div>
					<TextArea
						className="!mb-0 grow mr-4 max-h-[192px]"
						name={"randomValue"}
						showError={false}
						value={aiResult || ""}
					/>
				</div>
			</div>

			<div className="flex flex-row mt-5 border-t pt-5 border-gray-200">
				<div className="w-1/2 mr-2 grow">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						disabled={currentLead === 0}
						image={<Icon className="mr-2" icon={faArrowLeft} />}
						size={ButtonSize.S}
						title="Previous lead"
						onClick={handlePreviousLead}
					/>
				</div>
				<div className="w-1/2 ml-2 grow">
					<Button
						color={ButtonColor.ACTION_SECONDARY}
						disabled={currentLead === inbox.length - 1}
						iconRight={true}
						image={<Icon className="ml-2" icon={faArrowRight} />}
						size={ButtonSize.S}
						title="Next lead"
						onClick={handleNextLead}
					/>
				</div>
			</div>

			{hasPermissions([PermissionRoles.GLOBAL_ADMIN]) && (
				<div className="flex flex-row mt-5 border-t pt-5 border-gray-200 text-gray-700 items-center text-sm">
					<span className="mr-1">{getValues("online") ? "3" : "1"}x</span>
					<Icon className="w-[16px] h-[16px] text-gray-700 mr-1" icon={faCoin} />
					<span className="">per row</span>
				</div>
			)}
			{getAllErrors(error, formErrors).length ? (
				<div className="mt-4 mb-0">{renderErrorMessages(getAllErrors(error, formErrors))}</div>
			) : null}
		</Modal>
	);
};
