import { api } from "@/redux/api";

import { RequestPaginationParams } from "@/types/request-params";

import { PersonSearch } from "../../pages/LiPeople/schema/liPeople";
import { AiColumn } from "../../pages/List/schema/ai-column";
import { ColumnConfig, ColumnConfigSchema } from "../../pages/List/schema/columnConfig";
import { ColumnValue } from "../../pages/List/schema/columnValue";
import { CustomColumn, UpdateList } from "../../pages/List/schema/list";
import { TableConfig, TableConfigSchema } from "../../pages/List/schema/tableConfig";
import { GoogleNewsResponse } from "../../pages/ProfileTracker/schema/google-news.interface";
import { LiCompanyActivitiesInterface } from "../../pages/ProfileTracker/schema/li-company-activity-interface";
import {
	CreateTracker,
	Tracker,
	TrackerFile,
	TrackerFileSchema,
	TrackerRequestPaginationParams,
	TrackerResponse,
	TrackerResponseSchema,
	TrackerSchema,
} from "../../pages/ProfileTracker/schema/profileTracker";
import { CreateTrackerColumn } from "../../pages/ProfileTracker/schema/trackerColumn";
import {
	TrackerOrganization,
	TrackerOrganizationResponse,
	TrackerOrganizationResponseSchema,
	TrackerOrganizationSchema,
} from "../../pages/ProfileTracker/schema/trackerOrganizations";

export const profileTrackerApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getProfileTrackers: builder.query<TrackerResponse, RequestPaginationParams>({
			query: ({ sorting, ...params }) => ({
				url: "tracker",
				params: {
					...(params && { ...params }),
					...(sorting && { ...sorting }),
				},
				responseSchema: TrackerResponseSchema,
			}),
			providesTags: ["ProfileTracker"],
		}),
		getTrackerTableConfig: builder.query<TableConfig, number>({
			query: (id: number) => ({
				url: `tracker/config/${id}`,
				responseSchema: TableConfigSchema,
			}),
			providesTags: ["TrackerTableConfig"],
		}),
		createTrackerTableConfig: builder.mutation<Partial<TableConfig>, Partial<TableConfig>>({
			query: (body) => ({
				url: `tracker/config`,
				method: "POST",
				body,
				responseSchema: TableConfigSchema,
			}),
			invalidatesTags: ["TrackerTableConfig"],
		}),
		addPeopleToTrackerOrganization: builder.mutation<
			Partial<TrackerOrganization>,
			{ people: PersonSearch[]; id: number }
		>({
			query: ({ id, people }) => ({
				url: `tracker-organization/${id}`,
				method: "POST",
				body: {
					linkedinUrls: people.map((person) => person.navigationUrl),
				},
				responseSchema: TrackerOrganizationSchema,
			}),
			invalidatesTags: (result, error, arg) => [
				"TrackerOrganization",
				"Column",
				{ type: "ProfileTracker", id: arg.id },
			],
		}),
		removePersonFromTrackerOrganization: builder.mutation<
			Partial<TrackerOrganization>,
			{ personId: number; id: number }
		>({
			query: ({ id, personId }) => ({
				url: `tracker-organization/${id}/${personId}`,
				method: "DELETE",
				responseSchema: TrackerOrganizationSchema,
			}),
			invalidatesTags: (result, error, arg) => [
				"TrackerOrganization",
				"Column",
				{ type: "ProfileTracker", id: arg.id },
			],
		}),
		updateTrackerTableConfig: builder.mutation<Partial<TableConfig>, Partial<TableConfig>>({
			query: ({ id, ...body }) => ({
				url: `tracker/config/${id}`,
				method: "PATCH",
				body,
				responseSchema: TableConfigSchema,
			}),
			invalidatesTags: ["TrackerTableConfig"],
		}),
		getTracker: builder.query<Tracker, number>({
			query: (id) => ({
				url: `tracker/${id}`,
				responseSchema: TrackerSchema,
			}),
			providesTags: (result, error, arg) => [{ type: "ProfileTracker", id: result?.id || arg }],
		}),
		getTrackerOrganizations: builder.query<
			TrackerOrganizationResponse,
			TrackerRequestPaginationParams
		>({
			query: ({ sorting, filter, trackerId, ...params }) => ({
				url: `tracker-organization/${trackerId}`,
				params: {
					...(params && { ...params }),
					...(sorting && { ...sorting }),
					...(filter && { filter: JSON.stringify(filter) }),
				},
				responseSchema: TrackerOrganizationResponseSchema,
			}),
			providesTags: ["TrackerOrganization"],
		}),
		createTracker: builder.mutation<Partial<Tracker>, CreateTracker>({
			query: (body) => ({
				url: `tracker`,
				method: "POST",
				body,
				responseSchema: TrackerSchema,
			}),
			invalidatesTags: ["ProfileTracker"],
		}),
		createTrackerFile: builder.mutation<Partial<TrackerFile>, FormData>({
			query: (body) => ({
				url: `tracker-file`,
				method: "POST",
				body,
				responseSchema: TrackerFileSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.get("trackerId")) {
					return [
						{ type: "ProfileTracker", id: +(arg.get("trackerId") || 0) },
						"ProfileTrackerFile",
					];
				}

				return ["ProfileTrackerFile"];
			},
		}),
		updateTrackerFile: builder.mutation<Partial<TrackerFile>, Partial<TrackerFile>>({
			query: ({ id, ...body }) => ({
				url: `tracker-file/${id}`,
				method: "PATCH",
				body,
				responseSchema: TrackerFileSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.trackerId) {
					return [{ type: "ProfileTracker", id: +(arg.trackerId || 0) }, "ProfileTrackerFile"];
				}

				return ["ProfileTrackerFile"];
			},
		}),
		updateTracker: builder.mutation<Partial<Tracker>, Partial<Tracker>>({
			query: ({ id, ...body }) => ({
				url: `tracker/${id}`,
				method: "PATCH",
				body,
				responseSchema: TrackerSchema,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.id) {
					return [{ type: "ProfileTracker", id: +(arg.id || 0) }];
				}

				return ["ProfileTracker"];
			},
		}),

		deleteTrackerFile: builder.mutation<Partial<TrackerFile>, number>({
			query: (id) => ({
				url: `tracker-file/${id}`,
				method: "DELETE",
				responseSchema: TrackerFileSchema,
			}),
			invalidatesTags: ["ProfileTrackerFile"],
		}),
		deleteTracker: builder.mutation<number, number>({
			query: (id) => ({
				url: `tracker/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["ProfileTracker"],
		}),
		addColumnToTracker: builder.mutation<Partial<Tracker>, CreateTrackerColumn>({
			query: ({ id, ...body }) => ({
				url: `tracker-column/${id}`,
				method: "POST",
				body,
				responseSchema: TrackerSchema,
			}),
			invalidatesTags: (result, error, arg) => [
				"TrackerOrganization",
				"Column",
				{ type: "ProfileTracker", id: arg.id },
			],
		}),
		deleteColumn: builder.mutation<number, Partial<UpdateList>>({
			query: ({ id }) => ({
				url: `tracker-column/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result) => {
				return ["TrackerOrganization", "Column", { type: "ProfileTracker", id: result }];
			},
		}),
		getTrackerColumnById: builder.query<ColumnConfig, number>({
			query: (id) => ({
				url: `tracker-column/${id}`,
				responseSchema: ColumnConfigSchema,
			}),
			providesTags: (result, error, arg) => [{ type: "Column", id: arg }],
		}),
		updateColumnValue: builder.mutation<{ id: number; columnValue: ColumnValue }, CustomColumn>({
			query: ({ id, ...body }) => ({
				url: `tracker-column/${id}`,
				method: "PATCH",
				body,
			}),
		}),
		runAiPrompt: builder.mutation<{ data: string }, AiColumn>({
			query: ({ id, ...body }) => ({
				url: `tracker-column/run/${id}`,
				method: "POST",
				body,
			}),
		}),
		runSearch: builder.mutation<{ data: GoogleNewsResponse }, AiColumn>({
			query: ({ id, ...body }) => ({
				url: `tracker-column/search/${id}`,
				method: "POST",
				body,
			}),
		}),
		runLinkedinPostSearch: builder.mutation<{ data: LiCompanyActivitiesInterface }, AiColumn>({
			query: ({ id, ...body }) => ({
				url: `tracker-column/linkedin-post-search/${id}`,
				method: "POST",
				body,
			}),
		}),
	}),
});

export const {
	useGetProfileTrackersQuery,
	useLazyGetProfileTrackersQuery,
	useGetTrackerQuery,
	useLazyGetTrackerQuery,
	useCreateTrackerMutation,
	useCreateTrackerFileMutation,
	useDeleteTrackerFileMutation,
	useUpdateTrackerFileMutation,
	useUpdateTrackerMutation,
	useDeleteTrackerMutation,
	useGetTrackerOrganizationsQuery,
	useLazyGetTrackerOrganizationsQuery,
	useGetTrackerTableConfigQuery,
	useCreateTrackerTableConfigMutation,
	useUpdateTrackerTableConfigMutation,
	useAddColumnToTrackerMutation,
	useDeleteColumnMutation,
	useUpdateColumnValueMutation,
	useRunAiPromptMutation,
	useRunSearchMutation,
	useRunLinkedinPostSearchMutation,
	useGetTrackerColumnByIdQuery,
	useAddPeopleToTrackerOrganizationMutation,
	useRemovePersonFromTrackerOrganizationMutation,
} = profileTrackerApi;
export const accountApiReducer = profileTrackerApi.reducer;
export const accountApiMiddleware = profileTrackerApi.middleware;
