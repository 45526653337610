import { FunctionComponent } from "react";

import { classNames } from "@/utils/classNames";

import { ColumnValue } from "../../../List/schema/columnValue";

export interface CustomTextColumnReadOnlyProps {
	columnValue?: ColumnValue;
}

export const CustomTextColumnReadOnly: FunctionComponent<CustomTextColumnReadOnlyProps> = ({
	columnValue,
}) => {
	const cVal = columnValue?.value;

	return (
		<div
			className={classNames(
				"h-[26px] min-w-[150px] cursor-pointer leading-tight rounded p-1",
				"text-left  w-full"
			)}
		>
			{cVal?.answer || cVal?.title || cVal?.commentary || cVal || ""}
		</div>
	);
};
